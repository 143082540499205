module.exports ={
  "propertyService": "https://4gxcx3m3vh.execute-api.us-east-1.amazonaws.com/moises2",
  "domusoApiService": "https://bxt07x7gv3.execute-api.us-east-1.amazonaws.com/moises2",
  "appService": "https://domusograilsweb-moises2.devmuso.com",
  "reservationService": "https://k65sv7iudd.execute-api.us-east-1.amazonaws.com/moises2",
  "userPoolId": "us-east-1_kPRU6HBdW",
  "authService": "https://q9qtq0ko9a.execute-api.us-east-1.amazonaws.com/moises2",
  "userPoolWebClientId": "22uckdipuae3ktona5v6ttgv9s",
  "emailLinkBase": "https://domusograilsweb-moises2.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-dev@devmuso.com",
  "residentWebUI": "https://resident-moises2.devmuso.com",
  "maintenanceUrl": "https://q9qtq0ko9a.execute-api.us-east-1.amazonaws.com/moises2/maintenance",
  "env": "moises2"
}